:root {
  --g-color-black: #000;
  --g-color-white: #fff;

  --g-color-grey-50: #f6f6f6;
  --g-color-grey-600: #636363;
  --g-color-grey-700: #4d4d4d;
  --g-color-grey-900: #0f0f0f;

  --g-box-shadow: 0px 6px 12px rgba(18, 20, 23, 0.06),
    0px 15px 24px rgba(18, 20, 23, 0.07), 0px -4px 12px rgba(18, 20, 23, 0.05);

  --g-font-family: Helvetica, sans-serif;

  --g-font-size-x-small: 0.75rem; /* 12px */
  --g-font-size-small: 0.875rem; /* 14px */
  --g-font-size-base: 1rem; /* 16px */
  --g-font-size-large: 1.25rem; /* 20x */
  --g-font-size-x-large: 1.5rem; /* 24px */
  --g-font-size-2x-large: 2rem; /* 32px */
  --g-font-size-3x-large: 2.5rem; /* 40px */
  --g-font-size-4x-large: 4rem; /* 64px */

  --g-font-weight-base: 400;
  --g-font-weight-semi-bold: 500;
  --g-font-weight-bold: 600;
  --g-font-weight-black: 700;

  --g-border-radius-small: 0.5rem;
  --g-border-radius-base: 1rem;
  --g-border-radius-large: 1.5rem;

  --g-spacing-small: 0.5rem; /* 8px */
  --g-spacing-base: 1rem; /* 16px */
  --g-spacing-large: 1.5rem; /* 24px */
  --g-spacing-x-large: 2rem; /* 32px */
  --g-spacing-2x-large: 2.5rem; /* 40px */
  --g-spacing-3x-large: 3rem; /* 48px */
  --g-spacing-6x-large: 6rem; /* 96px */
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: var(--g-font-family);
}

button {
  appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: none;
}

.text-subtle {
  color: var(--g-color-grey-600);
  font-size: var(--g-font-size-x-small);
  font-weight: var(--g-font-weight-base);
}

.text-body-1 {
  font-size: var(--g-font-size-2x-large);
  font-weight: var(--g-font-weight-base);
}

.text-body-2 {
  font-size: var(--g-font-size-x-large);
  font-weight: var(--g-font-weight-base);
}

.text-body-3 {
  color: var(--g-color-grey-900);
  font-size: var(--g-font-size-small);
  font-weight: var(--g-font-weight-base);
}

.text-display-1 {
  font-size: var(--g-font-size-4x-large);
  font-weight: var(--g-font-weight-black);
  line-height: 1.2;
}

.text-display-2 {
  font-size: var(--g-font-size-3x-large);
  font-weight: var(--g-font-weight-black);
  line-height: 1.4;
}

.text-display-3 {
  font-size: var(--g-font-size-x-large);
  font-weight: var(--g-font-weight-black);
}

.text-heading-1 {
  font-size: var(--g-font-size-large);
  font-weight: var(--g-font-weight-semi-bold);
}

.text-heading-2 {
  font-size: var(--g-font-size-base);
  font-weight: var(--g-font-weight-semi-bold);
}

.container {
  padding: 0 var(--g-spacing-6x-large);
  margin: auto;
}

.nav {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: var(--g-spacing-x-large);
  padding-top: var(--g-spacing-x-large);
  width: 100%;
}

.sign-in-btn {
  margin-right: var(--g-spacing-small);
}

.btn {
  border-radius: var(--g-border-radius-small);
  display: inline-block;
  font-weight: var(--g-font-weight-bold);
  padding: var(--g-spacing-base);
}

.btn-ghost {
  color: var(--g-color-grey-700);
}

.btn-dark {
  background-color: #fa3f40;
  color: var(--g-color-white);
}

.btn-light {
  background: var(--g-color-white);
  color: var(--g-color-black);
  font-weight: 600;
}

.btn-big {
  font-size: var(--g-font-size-large);
  padding: var(--g-font-size-large) var(--g-font-size-x-large);
}

.hero {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 45rem;
  justify-content: center;
  text-align: center;
}

.hero-title {
  margin-bottom: var(--g-spacing-x-large);
}

.hero-tagline {
  margin-bottom: var(--g-spacing-3x-large);
}

.card {
  background: #f2e4d9;
  border-radius: var(--g-border-radius-large);
  box-shadow: var(--g-box-shadow);
  color: #000;
}

.link {
  text-decoration: underline;
  text-underline-offset: 0.2rem;
}

.link:hover,
.link:focus {
  background: #f1f2f4;
}

.footer {
  padding-bottom: var(--g-spacing-x-large);
  padding-top: var(--g-spacing-x-large);
}

.footer-tagline {
  margin-bottom: var(--g-font-size-x-small);
  margin-top: var(--g-font-size-x-small);
}

.start-hero {
  padding: var(--g-spacing-2x-large);
  text-align: center;
}

.start-hero-intro {
  margin-bottom: var(--g-spacing-base);
}

.avatar {
  align-items: center;
  background-color: var(--g-color-grey-50);
  border-radius: var(--g-border-radius-large);
  display: flex;
  height: var(--g-spacing-3x-large);
  justify-content: center;
  text-align: center;
  width: var(--g-spacing-3x-large);
}

.profile-blob {
  align-items: center;
  display: grid;
  gap: var(--g-spacing-base);
  grid-template-columns: auto 1fr;
}

.next-steps-section {
  margin-top: var(--g-spacing-2x-large);
}

/* new styles */
.l-layout {
  display: flex;
  height: 100vh;
}

.c-sidebar {
  background-color: #dee7e8;
  flex-grow: 0;
  padding: 1rem;
}

.c-nav-links {
  display: grid;
  gap: 1.25rem;
  list-style: none;
}

.c-nav-link {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  text-align: center;
  width: 100%;
}

.c-logo,
.c-nav-link__icon {
  background-color: #fff;
  border-radius: 1rem;
  color: #6b71ff;
  display: block;
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-logo {
  display: block;
  margin: 0 auto 2rem;
}

.c-nav-link__icon .feather {
  height: 1.25rem;
  width: 1.25rem;
}

.c-nav-link__icon :not(.feather) {
  margin-top: 0.25rem;
}

.c-nav-link__text {
  color: #6b71ff;
  font-size: 0.75rem;
}

.c-nav-link.is-active .c-nav-link__icon {
  background-color: #090fff;
  color: #fff;
}

.c-nav-link.is-active .c-nav-link__text {
  color: #090fff;
  font-weight: 500;
}

.c-avatar-container {
  position: fixed;
  top: 1rem;
  right: 1rem;
}

.c-avatar {
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 1rem;
}

.c-card {
  padding: 1.5rem;
  border-radius: 1.5rem;
  position: relative;
}

.c-card--outline {
  border: 1px solid #e0e0e0;
}

.c-card--purple {
  background-color: #f7e0fe;
}

.c-card--orange {
  background-color: #fee5db;
}

.c-card--has-image {
  padding-top: 4.5rem;
  text-align: center;
}
.c-card__image {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 7.5rem;
  width: 7.5rem;
  border-radius: 1rem;
}
dt {
  font-weight: 700;
}
.l-main {
  padding: 10rem 2rem;
}

.l-main__start {
  display: flex;
  gap: 1rem;
}

.c-heading {
  font-weight: 700;
  font-size: 1rem;
}

.c-patient-data {
  display: flex;
  justify-content: space-between;
  gap: 3rem;
  text-align: center;
}

.c-patient-data dt {
  text-transform: uppercase;
  color: #090fff;
  font-weight: 700;
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
}

.c-patient-data dd {
  font-size: 0.875rem;
}

.c-logged-out-logo {
  height: 100px;
  width: 100px;
}

.h-spacing {
  margin-bottom: 1rem;
}

.h-hide-visually {
  /* stylelint-disable-next-line declaration-property-value-allowed-list */
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  /* stylelint-disable-next-line unit-allowed-list */
  height: 1px !important;
  /* stylelint-disable-next-line declaration-property-value-allowed-list, unit-allowed-list */
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important; /* 1 */
  /* stylelint-disable-next-line unit-allowed-list */
  width: 1px !important;
}
